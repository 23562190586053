"use strict";

// var timetable = `
// 	Montag	Dienstag	Mittwoch	Donnerstag	Freitag	Samstag/Sonntag
// Zeit	15:30 - 16:15		15:00 -15:45	14:45 -15:30	14:30 - 15:20
// Kurs	Dancekids 		Ballett	Dancekids 	Ballett	Kindergeburtstage
// Alter	ab 9 Jahre		6 J.	ab 7 J.	 9 -11J.	Tanzen und mehr !
// Stufe (A M F)			Leistungskurs		Gruppe 1
// Special (Neu Plan)				NEU
// Zeit		15:30- 16:25	16:00 - 16:55	15:30 -16:20	15:30 - 16:20
// Kurs		Ballett Anfänger	Ballett 	Hip Hop	Ballett	Workshops
// Alter		12-15 J.	ab 8 Jahre	Crew 	9 -12 J.	Tanztrends und mehr
// Stufe (A M F)				A	Gruppe 2
// Special (Neu Plan)		NEU	Leistungskurs	ab Sept.!!!
// Zeit	17:30 - 18:30	16:30 - 18:00	17:00 - 18:30	16:25- 17:15	16:30 - 17:15
// Kurs	Dancemix	Ballett mit Spitze	Ballett/Modern 	Hip Hop 	Vorschulballett	Entspannung
// Alter	ab 13 Jahre	ab 13 J.	Schüler / Studenten 	ab 13 J.	4 - 6 Jahre	Meditation
// Stufe (A M F)	A	M	F+	M/F		Termine erfragen
// Special (Neu Plan)			mit Spitze	ab Sept. !!!
// Zeit	18:45 - 20:15	18:00 -19:00	18:45 - 20:00	17:15 -18:05	17:30 - 19:00
// Kurs	Female/ Showdance	Ballett Anfänger	KPOP	Contemporary 	Ballett/Spitze 	Lachyoga
// Alter	ab 17 J.	Frauen	ab 15 J.	ab 13 Jahre	Schüler/Studenten
// Stufe (A M F)	M	in Planung		A	F+	Termine erfragen
// Special (Neu Plan)		NEU		ab Sept. !!
// Zeit		19:00- 20:00		18:10-19:10	19:05 - 20:05
// Kurs		Stepptanz		Hip Hop	Contemporary	Tanzkurse
// Alter		Erwachsene Ü35		ab 13 J.	Schüler/Studenten	Standard/Latein
// Stufe (A M F)		M/F		M/F	M/F
// Special (Neu Plan)				ab Sept.!!!
// Zeit		20:00 - 21:45			20:15- 21:15
// Kurs		Tanzkurs			Contemporary 	Privatstunden
// Alter		 Privatstunde			Erwachsene Ü40 	Hochzeit & more
// Stufe (A M F)					A im Wechsel
// Special (Neu Plan)

// A=Anfänger
// M=Mittelstufe
// F=Fortgeschrittene
// F+ sehr gute Kenntnisse
// `;

var url =
  // "https://docs.google.com/spreadsheets/d/e/2PACX-1vQnusRFW_i0K-sopvXl074eRSqopUur_tCVqfnHxfSaixfbZqAnj0FAUEZx8rlKWWtHz5H0oCpVIvBw/pub?output=tsv";
  "https://us-central1-danceline-sebi.cloudfunctions.net/timetable";

export function loadTimetable(callback) {
  // callback(render(timetable));
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, false);
  xhr.addEventListener("load", () => {
    callback(render(xhr.responseText));
  });
  xhr.addEventListener("error", (err) => {
    console.log("ERROR retrieving timetable, ", err);
  });
  xhr.send();

  function render(text) {
    var rows = text.split("\n");
    var markupRows = [];
    var markupRowNumber = 0;
    rows.forEach(function (row, rowIndex) {
      if ((rowIndex - 2) % 5 != 0) return;
      var columnTimes = (row || "").split("\t").slice(1);
      var columnNames = (rows[rowIndex + 1] || "").split("\t").slice(1);
      var columnAges = (rows[rowIndex + 2] || "").split("\t").slice(1);
      var columnLevels = (rows[rowIndex + 3] || "").split("\t").slice(1);
      var columnAttr = (rows[rowIndex + 4] || "").split("\t").slice(1);
      var markupRow = (markupRows[markupRowNumber++] = []);

      columnTimes.forEach((_, columnIndex) => {
        markupRow.push({
          time: columnTimes[columnIndex] || "",
          name: (columnNames[columnIndex] || "").replace("---", "<br>"),
          age: (columnAges[columnIndex] || "").replace("---", "<br>"),
          level: columnLevels[columnIndex] || "",
          attr: columnAttr[columnIndex] || "",
        });
      });
    });
    var rendered = markupRows
      .map((row) => {
        return (
          "<tr>" +
          row
            .map((column) => {
              var c = [column.attr, column.level]
                .join(" ")
                .split(" ")
                .map((t) => t.trim().toLowerCase())
                .join(" ");
              return "<td class='#class#' #level#><div class='time'>#time#</div><div class='name'>#name#</div><div class='age'>#age#</div></td>"
                .replace("#time#", column.time.replace("‐", "&dash;"))
                .replace("#name#", column.name)
                .replace("#age#", column.age)
                .replace("#class#", column.attr.trim().toLowerCase())
                .replace(
                  "#level#",
                  column.level.trim()
                    ? "level='" + column.level.trim() + "'"
                    : ""
                );
            })
            .join("") +
          "</tr>"
        );
      })
      .join("");
    return rendered;
  }
}

loadTimetable((text) => {
  window.document.querySelector(".timetable tbody").innerHTML = text;
});
